<template>
    <Menubar 
    :pt="
    {             
        content : { class : 'flex flex-row justify-center items-center' },
        // submenu : { class : 'sm:text-black' }
    }" 
    :model="items" class="bg-whte border-gray-200 dark:bg-gray-900 h-16">
      <template #start>
        <NuxtLink to="/">
            <img src="~/assets/img/logo.png" class="h-12 mt-2" alt="케이씨파워" />
        </NuxtLink> 
      </template>
      
      <template #item="{ item, props, hasSubmenu, root }">        
        
        <NuxtLink v-if="item.route" :to="item.route">
            <a v-bind="props.action" class="font-black flex items-center rtl:space-x-reverse hover:bg-red-800 hover:text-white hover:rounded-md">
                <i :class="item.icon" />
                <span :class="{'text-center text-black' :isCsRoute}">{{ item.label }}</span>

                <i v-if="hasSubmenu" :class="['pi pi-angle-down', { 'ml-2': root }]"></i>
            </a>
        </NuxtLink>
        <a v-else v-bind="props.action" class="font-black flex items-center space-x-3 rtl:space-x-reverse hover:bg-red-800 hover:text-white hover:rounded-md">
        <i :class="item.icon" />
        <span class="text-center">{{ item.label }}</span>
        <i v-if="hasSubmenu" :class="['pi pi-angle-down', { 'ml-2': root }]"></i>
        </a>
      </template>    
      <template #end>
        <Button class="w-17 h-5" v-if=useAuthStore().isLoggedIn label="관리자 로그아웃" @click="useAuthStore().logout()"/>
      </template>
    </Menubar>
</template>
<script setup>
import { ref } from "vue";
const isCsRoute = computed(() => useRoute().path.startsWith('/cs'));
const items = ref([
    {
        label: '회사소개',        
        items : [
            {
                label: '회사개요',                        
                route: '/about/overview'        
            },
            {
                label: 'CEO인사말',                
                route: '/about/ceo-message'        
            },
            {
                label: '오시는길',                
                route: '/about/way-come'        
            },
        ]
    },
    {
        label: '제품소개',        
        route: '/product'
    },
    // {
    //     label: '홍보센터',
    //     icon: 'pi pi-search',
    //     items: [
    //         {
    //             label: '보도자료',
    //             icon: 'pi pi-bolt',
    //             route: '/pr/press'
    //         },
    //         {
    //             label: '카탈로그',
    //             icon: 'pi pi-server',
    //             route: '/pr/catalog'
    //         },
    //     ]
    // },
    {
        label: '고객지원',        
        items : [
            {
                label: '공지사항',                
                route: '/cs/announcement'
            },
            {
                label: '상담문의',                
                route: '/cs/contact'
            },
        ]
    },
    {
        label: '인재채용',        
        items : [
            {
                label: '직무소개',                
                route: '/hr/job-intro'
            },
            {
                label: '채용프로세스',                
                route: '/hr/process'
            },
            // {
            //     label: '복리후생',                
            //     route: '/hr/welfare'
            // },
        ]
    }
]);
</script>