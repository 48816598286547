<template>
<div class="bg-gray-200 w-full bottom-0 left-0 text-left py-10 h-32">
    <div class="footer-text ml-10 md:ml-20">
        <span class="text-xs font-light text-black opacity-75">
            대표 : 이영수 &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp; 대표번호 : +82-32-816-8034 &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp; 메일 : peskorea@hanmail.net
            &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
            주소(제1공장) : 인천광역시 남동구 능허대로 633 (138B 11LOT)  
            &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
            주소(제2공장) :인천광역시 남동구 능허대로 649번길 83 (129B 6LOT)
        </span>
    </div>
    <div class="footer-text ml-10 md:ml-20">
        <span class="text-xs font-light text-black opacity-75">Copyright © 2024 KCPOWER ALL RIGHTS RESERVED</span>
    </div>
</div>
</template>